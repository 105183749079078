import React, { useState } from 'react';
import { message } from 'antd';
import { Loader2, LockIcon } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';

import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { setOpenPayModal, setShowUpgradeModal } from '../../redux/thread/threadSlice';
import { Button } from '../ui/Button';

import { _createSubscription, createSetupIntent } from '../../services/planAPI';

const stripeElementStyle = {
  base: {
    fontSize: '16px',
    color: '#ffffff',
    fontFamily: '"Inter", sans-serif',
    '::placeholder': {
      color: 'rgba(255, 255, 255, 0.4)',
    },
    iconColor: '#ffffff',
  },
  invalid: {
    color: '#ff5555',
    iconColor: '#ff5555',
  },
};

const stripeElementClasses =
  'w-full px-4 py-3 rounded-lg bg-white/10 border border-white/20 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors';

export function CreditCardForm({
  // onSubmit,
  isSubmitting,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { selectedPlan } = useSelector((state) => state.thread);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [elementStatus, setElementStatus] = useState({
    cardNumber: { error: null, complete: false },
    cardExpiry: { error: null, complete: false },
    cardCvc: { error: null, complete: false },
  });

  const handleElementChange = (event, elementType) => {
    setElementStatus((prev) => ({
      ...prev,
      [elementType]: {
        error: event.error ? event.error.message : null,
        complete: event.complete,
      },
    }));

    // if (event.error) {
    //   setError(event.error.message);
    // } else {
    // }
    setError(null);
  };

  const isFormComplete = () => {
    return elementStatus.cardNumber.complete && elementStatus.cardExpiry.complete && elementStatus.cardCvc.complete;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!stripe || !elements) {
      setError('Stripe has not been initialized.');
      return;
    }

    if (!isFormComplete()) {
      setError('Please fill in all fields correctly.');
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      setError('Card element not found.');
      return;
    }

    setLoading(true);

    try {
      // create a payment method
      let paymentMethod;
      let paymentId;
      let customerId;
      let msg = 'Your subscription started successfully!';
      if (/* !user.pm_last_four || (user.pm_last_four && cardType === 2) */ true) {
        let { data } = await createSetupIntent();
        const { setupIntent, error: setupError } = await stripe?.confirmCardSetup(data.clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: user.name,
              email: user.email,
            },
          },
        });

        if (setupError) {
          // console.log(setupError);
          if (setupError.code)
            setElementStatus((prev) => ({
              ...prev,
              [setupError.code?.replace('incomplete_', '').replace('incorrect_', '') || 'cardNumber']:
                setupError.message,
            }));
          message.error(setupError.message);
          setLoading(false);
          return;
        }
        // console.log(setupIntent);
        paymentMethod = setupIntent.payment_method;
        customerId = data.customerId;
      }

      let { data } = await _createSubscription({
        paymentMethod: paymentMethod,
        plan: selectedPlan,
        customerId,
      });
      if (data.status === 'requires_action') {
        const { clientSecret } = data;
        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret);
        if (confirmError) {
          // console.error(confirmError);
          message.error(confirmError.message);
          setLoading(false);
          return;
        }
        paymentId = paymentIntent.id;
        // console.log(resp);
        console.log(paymentIntent);
        // return;
        data = await _createSubscription({
          paymentMethod: paymentMethod,
          plan: selectedPlan,
          customerId,
          paymentIntentId: paymentIntent.id,
        });
      }
      if (data.status === 'trialing') {
        msg = 'Your free trial subscription started successfully!';
      } else if (data.requiresAction) {
        const { paymentIntentId } = data;
        const { error: confirmError } = await stripe.confirmCardPayment(paymentIntentId);
        paymentId = paymentIntentId;
        if (confirmError) {
          console.error(confirmError);
          return;
        }
        console.log('Subscription confirmed!');
      } else {
        console.log('Subscription created without additional authentication');
      }

      window.gtag('event', 'conversion', {
        send_to: 'AW-16565329006/84qjCODxss0ZEO64-9o9',
        transaction_id: paymentId,
      });
      setLoading(false);
      message.success(msg);
      dispatch(setOpenPayModal(false));
      dispatch(setShowUpgradeModal(false));
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response?.data?.message) {
        message.error(error.response.data.message);
      } else if (error.message) {
        message.error(error.message);
      }
    }

    // onSubmit({
    //   token: paymentMethod.id,
    //   name: cardName.trim(),
    // });
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="mt-6">
        <label className="block text-sm font-medium text-white/80 mb-1">Card Number</label>
        <div className={`${stripeElementClasses} ${elementStatus.cardNumber.error ? 'border-red-500' : ''}`}>
          <CardNumberElement
            options={{
              style: stripeElementStyle,
              showIcon: true,
            }}
            onChange={(e) => handleElementChange(e, 'cardNumber')}
            className="py-1"
          />
        </div>
        {elementStatus.cardNumber.error && (
          <p className="mt-1 text-sm text-red-400">{elementStatus.cardNumber.error}</p>
        )}
      </div>

      <div className="grid grid-cols-2 gap-4 mt-6">
        <div>
          <label className="block text-sm font-medium text-white/80 mb-1">Expiry Date</label>
          <div className={`${stripeElementClasses} ${elementStatus.cardExpiry.error ? 'border-red-500' : ''}`}>
            <CardExpiryElement
              options={{
                style: stripeElementStyle,
              }}
              onChange={(e) => handleElementChange(e, 'cardExpiry')}
              className="py-1"
            />
          </div>
          {elementStatus.cardExpiry.error && (
            <p className="mt-1 text-sm text-red-400">{elementStatus.cardExpiry.error}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-white/80 mb-1">CVC</label>
          <div className={`${stripeElementClasses} ${elementStatus.cardCvc.error ? 'border-red-500' : ''}`}>
            <CardCvcElement
              options={{
                style: stripeElementStyle,
              }}
              onChange={(e) => handleElementChange(e, 'cardCvc')}
              className="py-1"
            />
          </div>
          {elementStatus.cardCvc.error && <p className="mt-1 text-sm text-red-400">{elementStatus.cardCvc.error}</p>}
        </div>
      </div>

      {error && <div className="text-red-400 text-sm mt-2">{error}</div>}

      <Button
        type="submit"
        disabled={isSubmitting || !stripe || !isFormComplete() || loading}
        className="w-full mt-6 cursor-pointer border-none"
        isLoading={loading}
      >
        {loading ? (
          <span className="flex items-center justify-center gap-2">
            <Loader2 className="w-5 h-5 animate-spin" />
            Processing...
          </span>
        ) : (
          <span className="flex items-center justify-center gap-2">
            {!(isSubmitting || !stripe || !isFormComplete() || loading) && <LockIcon />} Start Your Free Trial
          </span>
        )}
      </Button>
    </form>
  );
}
