import React from 'react';
import { motion } from 'framer-motion';
import { X, ChevronLeft, ChevronRight, CheckCircle2 } from 'lucide-react';

import { RoadmapSteps } from './RoadmapSteps';

export function StepModal({ step, onNext, onBack, onClose, currentStep, totalSteps, showTrialButton }) {
  return (
    <div className="relative bg-[#1A1A1A] rounded-2xl max-w-xl w-full mx-auto">
      {/* Image Container */}
      <div className="relative h-48 w-full overflow-hidden rounded-t-2xl">
        <img
          src="https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt={step.imageAlt}
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/30" />
        <RoadmapSteps currentStep={currentStep} totalSteps={totalSteps} />
        <motion.div
          className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(255,255,255,0.2),transparent)]"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.4, 0.6, 0.4],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: 'easeInOut',
          }}
        />
        {/* Secondary floating effect */}
        <motion.div
          className="absolute inset-0 bg-[radial-gradient(circle_at_25%_25%,rgba(255,255,255,0.15),transparent)]"
          animate={{
            scale: [1.2, 1, 1.2],
            opacity: [0.3, 0.5, 0.3],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: 'easeInOut',
            delay: 1,
          }}
        />
        {/* Moving light beam effect */}
        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent"
          animate={{
            x: ['-100%', '100%'],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: 'easeInOut',
            repeatDelay: 1,
          }}
        />
        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/30" />
      </div>

      <div className="absolute top-4 right-4 z-10">
        <button
          onClick={onClose}
          className="text-white/60 hover:text-white transition-colors bg-transparent border-none cursor-pointer"
        >
          <X className="w-6 h-6" />
        </button>
      </div>

      <div className="p-6">
        <div className="mb-6">
          <div className="flex items-center gap-2 text-white/40 text-sm mb-2">
            <div className="h-1 w-12 rounded-full bg-white/20">
              <div className="h-full rounded-full bg-white" style={{ width: `${(currentStep / totalSteps) * 100}%` }} />
            </div>
            <span>
              Step {currentStep} of {totalSteps}
            </span>
          </div>

          <div>
            <h2 className="text-2xl font-bold text-white mb-2">{step.title}</h2>
          </div>

          <p className="text-white/80 mb-4">
            <span dangerouslySetInnerHTML={{ __html: step.subtitle }} />
          </p>

          <div className="relative py-4 flex items-center">
            <div className="h-[2px] bg-white/10 flex-1"></div>
            <div className="relative flex justify-center">
              <span className="bg-[#1A1A1A] px-4 text-sm text-white/40 uppercase tracking-wider">
                {currentStep === totalSteps
                  ? "What You'll Experience"
                  : currentStep === totalSteps - 1
                    ? "What You'll Learn"
                    : currentStep === 1
                      ? 'What I Need to Understand'
                      : currentStep === 2
                        ? "What We'll Explore Together"
                        : 'What We Need to Know'}
              </span>
            </div>
            <div className="h-[2px] bg-white/10 flex-1"></div>
          </div>

          <ul className="space-y-2 mb-6">
            {step.bullets.map((bullet, index) => (
              <li key={index} className="flex gap-2 text-white/70">
                <CheckCircle2 className="w-5 h-5 text-green-400 flex-shrink-0 mt-[2px]" />
                <span>{bullet}</span>
              </li>
            ))}
          </ul>

          <div className="flex justify-between items-center">
            <motion.button
              onClick={onBack}
              disabled={currentStep === 1}
              whileHover={currentStep !== 1 ? { scale: 1.05 } : undefined}
              whileTap={currentStep !== 1 ? { scale: 0.95 } : undefined}
              className={`flex items-center border-none gap-1 px-4 py-2 rounded-lg transition-colors bg-white/10 ${
                currentStep === 1 ? 'text-white/30 cursor-not-allowed' : 'text-white hover:bg-white/10 cursor-pointer'
              }`}
            >
              <ChevronLeft className="w-5 h-5" />
              Back
            </motion.button>

            <div className="flex flex-col items-center">
              <motion.button
                onClick={onNext}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`border-none cursor-pointer flex items-center justify-center gap-2 w-full px-6 py-2 rounded-lg font-semibold ${
                  showTrialButton ? 'bg-white text-black hover:bg-white/90' : 'bg-white/10 text-white hover:bg-white/20'
                }`}
              >
                {showTrialButton ? 'Start Free Trial' : 'Next'}
                {!showTrialButton && <ChevronRight className="w-5 h-5" />}
              </motion.button>
              {showTrialButton && (
                <p className="text-center text-white/40 text-xs mt-2">
                  0 messages left today. Get unlimited with trial.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
