import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { CheckCircle2 } from 'lucide-react';

const analysisSteps = [
  {
    id: 'process',
    message: 'Processing conversation details...',
    duration: 2500,
  },
  {
    id: 'identify',
    message: 'Identifying key discussion points...',
    duration: 2500,
  },
  {
    id: 'create',
    message: 'Creating tailored insights...',
    duration: 2500,
  },
  {
    id: 'prepare',
    message: 'Preparing personalized guidance...',
    duration: 2500,
  },
  {
    id: 'finalize',
    message: 'Finalizing your discussion roadmap...',
    duration: 2500,
  },
];

export function LoadingRoadmap({ onComplete }) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);

  useEffect(() => {
    if (currentStepIndex >= analysisSteps.length) {
      setTimeout(onComplete, 500);
      return;
    }

    const timer = setTimeout(() => {
      setCompletedSteps((prev) => [...prev, analysisSteps[currentStepIndex].id]);
      setCurrentStepIndex((prev) => prev + 1);
    }, analysisSteps[currentStepIndex].duration);

    return () => clearTimeout(timer);
  }, [currentStepIndex, onComplete]);

  return (
    <div className="bg-black/50 border border-white/10 p-8 rounded-2xl w-full">
      <h2 className="text-2xl font-bold text-white text-center mb-6 bg-white/5 py-3 px-4 rounded-lg inline-block mx-auto w-full">
        Creating Your Customized Discussion Roadmap
      </h2>

      <div className="space-y-4 mb-6">
        {analysisSteps.map((step, index) => (
          <motion.div
            key={step.id}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.2 }}
            className={`flex items-center gap-3 text-left p-3 rounded-lg ${
              index === currentStepIndex
                ? 'bg-white/10'
                : completedSteps.includes(step.id)
                  ? 'opacity-50'
                  : 'opacity-30'
            }`}
          >
            {completedSteps.includes(step.id) ? (
              <CheckCircle2 className="w-5 h-5 text-green-400 flex-shrink-0" />
            ) : (
              index === currentStepIndex ? <img src="/imgs/loading.gif" alt="loading" className="w-4 h-4 invert" /> : <div className="w-4 h-4"/>
            )}
            <span className="text-white text-sm">{step.message}</span>
          </motion.div>
        ))}
      </div>

      <motion.div
        className="h-1 bg-white/10 rounded-full overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <motion.div
          className="h-full bg-white"
          initial={{ width: '0%' }}
          animate={{
            width: `${(currentStepIndex / analysisSteps.length) * 100}%`,
          }}
          transition={{ duration: 0.5 }}
        />
      </motion.div>
    </div>
  );
}
